import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import SEO from '../../components/seo'
import { breakpoints } from '../../utils/styles'
import SaleDetail from '../../components/MemorialSale'
import { ImitationsBlockStyle, ButtonStyle, Container } from '../../utils/styles'

import hero from '../../images/sales/memorial-sales-header.jpg'
import heroMobile from '../../images/sales/memorial-mobile-header.jpg'

const DesktopImg = styled.img`
  width: 100%;
  display: none;
  @media (min-width: ${breakpoints.m}px){
    display: block;
  }
`

const MobileImg = styled.img`
  width: 100%;
  display: block;
  @media (min-width: ${breakpoints.m}px){
    display: none;
  }
`

const CodeBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  font-size: 18px;
  font-family: 'HKNova-Regular';
  margin: auto;
  margin-top: 40px;
  max-width: 400px;

  .code-item {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: ${breakpoints.m}px){
    grid-template-columns: repeat(3, 1fr);
    max-width: 700px;

    .code-item {
      margin-bottom: 0;
    }

  }


`

const ImageBlock = (props) => (
  <>
    <DesktopImg alt={props.title} src={props.img} />
    <MobileImg alt={props.title} src={props.mobileImg} />
  </>
)

const ImitationsBlock = () => (
  <ImitationsBlockStyle className='imitations-block' style={{textAlign: 'center'}}>
    <h3>Spend More. Save More!</h3>
    <h1>Memorial Day Sale</h1>
    <p>This Memorial Day save BIG when you stock up on items for the summer!</p>
    <CodeBlock>
      <div className='code-item'>
        20% off $30+ 
        <ButtonStyle>
          <Link to='/sales/memorial-day?discount=MEM20'>Code MEM20</Link>
        </ButtonStyle>
      </div>
      <div className='code-item'>
        30% off $50+
        <ButtonStyle>
          <Link to='/sales/memorial-day?discount=MEM30'>Code MEM30</Link>
        </ButtonStyle>
      </div>
      <div className='code-item'>
        40% off $100+
        <ButtonStyle>
          <Link to='/sales/memorial-day?discount=MEM50'>Code MEM40</Link>
        </ButtonStyle>
      </div>
    </CodeBlock>
  </ImitationsBlockStyle>
)


const SalePage = () => {
  return (
    <Container>
      <SEO title="Memorial Day Sale" description="This Memorial Day save BIG when you stock up on items for the summer!" keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />
      <ImageBlock img={hero} mobileImg={heroMobile} title="Memorial Day" />
      <ImitationsBlock />
      <SaleDetail tag='Save Site Wide' title='Up to 40% off'/>
    </Container>
  )
}

export default SalePage
